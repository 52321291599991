const consent = document.getElementsByClassName("cookie-consent");
const accept = document.getElementById("js-cookie-accept");
const close = document.getElementById("js-cookie-close");

const consentAccepted = sessionStorage.getItem("cookie-consent");

if (!consentAccepted && consent.length > 0) {
    consent[0].style.display = "block";

    // #TODO implement
    accept.addEventListener("click", () => {
        sessionStorage.setItem("cookie-consent", "true");
        consent[0].style.display = "none";
    });

    close.addEventListener("click", () => {
        consent[0].style.display = "none";
    });
}
