const header = document.getElementById("js-header");
const navbar = document.getElementById("js-navbar");
const brand = document.getElementById("js-brand");
const navigation = document.getElementById("js-navigation");

const navbarMenu = document.getElementById("navbar-menu");
const navbarMenuOpen = document.getElementById("js-navbar-open");
const navbarMenuClose = document.getElementById("js-navbar-close");

const isAltStyle = header && header.dataset.style === "alt";
const isHero = header && header.dataset.type === "hero";
const isHeroAlt = header && header.dataset.type === "hero-alt";

let isNavbarSlim = false;
let isMenuOpen = false;

// velky navbar, zavrene menu
function setNormalClosed() {
    navbar.classList.remove("navbar_slim");
    navbar.classList.remove("bg-black", "navbar-dark");

    if (isHero) {
        navbar.classList.add("navbar_hero", "bg-transparent");
        navbar.classList.remove("bg-white");
    }

    if (isHeroAlt) {
        navbar.classList.add("navbar_hero-alt", "bg-transparent");
        navbar.classList.remove("bg-white");
    }

    if (isAltStyle) {
        navbar.classList.add("navbar-dark");
    }
}

// velky navbar, otevrene menu
function setNormalOpened() {
    navbar.classList.remove("bg-white", "bg-transparent", "navbar-dark");
    // navbar.classList.add("bg-black", "navbar-dark");
    navbar.classList.add("bg-white");
}

// maly navbar, zavrene menu
function setSlimClosed() {
    navbar.classList.add("navbar_slim", "bg-white");
    navbar.classList.remove("bg-black", "navbar-dark");

    if (isHero) {
        navbar.classList.remove("navbar_hero", "bg-transparent");
    }

    if (isHeroAlt) {
        navbar.classList.remove("navbar_hero-alt", "bg-transparent");
    }

    if (isAltStyle) {
        navbar.classList.remove("navbar-dark");
    }
}

// maly navbar, otevrene menu
function setSlimOpened() {
    navbar.classList.remove("bg-white");
    // navbar.classList.add("bg-black", "navbar-dark");
    navbar.classList.add("bg-white");
}

// slim menu
const callback = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            isNavbarSlim = false;

            if (isMenuOpen) {
                setNormalOpened();
            } else {
                setNormalClosed();
            }
        }

        if (!entry.isIntersecting) {
            isNavbarSlim = true;

            if (isMenuOpen) {
                setSlimOpened();
            } else {
                setSlimClosed();
            }
        }
    });
};

if (header) {
    const observer = new IntersectionObserver(callback);

    observer.observe(header);
}

// mobile menu
navbarMenu.addEventListener("hide.bs.collapse", (e) => {
    // zacatek zavirani menu
    console.log(e);

    navbarMenuOpen.classList.remove("d-none");
    navbarMenuClose.classList.add("d-none");
});

navbarMenu.addEventListener("hidden.bs.collapse", (e) => {
    // menu zavreno
    isMenuOpen = false;

    console.log(e);

    if (isNavbarSlim) {
        setSlimClosed();
    } else {
        setNormalClosed();
    }
});

navbarMenu.addEventListener("show.bs.collapse", (e) => {
    // zacatek otevirani menu
    isMenuOpen = true;

    console.log(e);

    navbarMenuOpen.classList.add("d-none");
    navbarMenuClose.classList.remove("d-none");

    if (isNavbarSlim) {
        setSlimOpened();
    } else {
        setNormalOpened();
    }
});

navbarMenu.addEventListener("shown.bs.collapse", (e) => {
    // menu otevreno
    console.log(e);
});
