const appFilters = document.getElementsByClassName("js-filter");
const appObjects = document.getElementsByClassName("js-object");

const hasFilters = !!appFilters.length;
const hasObjects = !!appObjects.length;

if (hasFilters && hasObjects) {
    for (let e of appFilters) {
        e.addEventListener("change", () => {
            evaluateFilters();
        });
    }
    for (let e of appObjects) {
        e.addEventListener("change", () => {
            evaluateFilters();
        });
    }
}

function evaluateFilters() {
    if (hasFilters && hasObjects) {
        const filters = {};
        for (let e of appFilters) {
            const filterKey = e.dataset.filterKey;
    
            filters[filterKey] = e.value;
            // const select = 
        }
    
        console.log(filters);
    
        for (let e of appObjects) {
            const filterAuthor = e.dataset.filterAuthor;
            const filterObject = e.dataset.filterObject;
    
            // console.log(filters);


            if (filters.author == "" && filters.object == "" || filters.object == filterObject || filters.author == filterAuthor) {
                console.log(e);
                e.classList.remove('d-none');
            } else {
                e.classList.add('d-none');
            }
        }
        // appObjects(e => {
        //     console.log(e.dataset.filterObject);
        // });
    }
}


evaluateFilters();

