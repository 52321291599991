const mute = document.getElementById("js-button-mute");
const muteIcon = document.getElementById("js-mute-icon-cross");
const video = document.getElementById("js-video");

if (mute && muteIcon && video) {
    mute.addEventListener("click", (e) => {
        if (video.muted) {
            // unmute
            // console.log("unmuted");
            video.muted = false;

            muteIcon.classList.add("d-none");
        } else {
            // mute
            // console.log("muted");
            video.muted = true;

            muteIcon.classList.remove("d-none");
        }
    });
}
