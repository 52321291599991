// import Swiper JS
import Swiper from "swiper";
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
// import Swiper styles
import "swiper/scss";
import "swiper/scss/autoplay";
import "swiper/scss/effect-fade";
import "swiper/scss/navigation";

const heroSlider = new Swiper(".js-slider-hero", {
    loop: true,
    speed: 2000,

    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },

    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },

    modules: [Autoplay, EffectFade],
});

const imageSlider = new Swiper(".js-slider-image", {
    slidesPerView: 1,

    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        1140: {
            slidesPerView: 2,
            spaceBetween: 32,
        },
    },
    loop: true,

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },

    modules: [Navigation],

    // on: {},
});
